import React from 'react';
import injectSheet from 'react-jss';
import Modal from 'react-modal';
import Helmet from 'react-helmet';

import Translate from '../../components/Translate';
import Layout from '../../components/layout';
import themeColors from '../../styles/theme';
import colors from '../../styles/colors';
import Button from '../../components/button';
import InfoSection from '../../components/infoSection';
import i18next from '../../config/i18next';
import { isChinaUser } from '../../config/isChina';

import HeroImage from '../../data/images/features/mct/desktop/hero.jpg';
import HeroImageMobile from '../../data/images/features/mct/mobile/hero_mobile.jpg';
import walkingImage from '../../data/images/features/mct/desktop/02_walking_girls.jpg';
import walkingImageMobile from '../../data/images/features/mct/mobile/02_walking_girls_mobile.jpg';
import phoneImage from '../../data/images/features/mct/desktop/phone.png';
import phoneImageMobile from '../../data/images/features/mct/mobile/phone_mobile.png';
import yogaImage from '../../data/images/features/mct/desktop/03_yoga.jpg';
import yogaImageMobile from '../../data/images/features/mct/mobile/03_yoga_mobile.jpg';
import teaImage from '../../data/images/features/mct/desktop/04_tea.jpg';
import teaImageMobile from '../../data/images/features/mct/mobile/04_tea_mobile.jpg';
import vivoactiveImage from '../../data/images/features/mct/desktop/vivoactive3m.png';

const styles = {
    landingPage: {
        background: themeColors.white,
        '& a': {
            color: themeColors.black,
            textDecorationLine: 'underline'
        },
        '& button a': {
            color: themeColors.primaryButtonLinkText,
            textDecorationLine: 'none'
        }
    },
    modal: {
        width: '800px',
        margin: '7% 0 0 15%',
        background: '#fff',
        '& iframe': {
            margin: '0 0 10px 20px'
        }
    },
    modalHeader: {
        padding: '7px 15px',
        color: '#222',
        overflow: 'hidden',
        lineHeight: '1',
        display: 'flex'
    },
    modalCloseButton: {
        color: '#888',
        cursor: 'pointer',
        border: '0',
        opacity: '1',
        padding: '0',
        fontSize: '15px',
        background: 'transparent',
        fontWeight: '400',
        lineHeight: '11px',
        margin: '7px 0 0 auto'
    },
    blogButton: {
        height: '44px',
        padding: '0 25px',
        fontSize: '16px',
        margin: 0,
        cursor: 'pointer',
        borderRadius: '4px',
        border: 'none',
        display: 'block',
        transition: 'background-color 200ms',
        backgroundColor: themeColors.primaryButtonBackground,
        color: (props) => themeColors.primaryButtonText,
        '&:focus': {
            backgroundColor: themeColors.primaryButtonBackgroundFocus,
            outline: 'none',
            boxShadow: '0 0 0 2px ' + colors.white + ', 0 0 0 4px ' + themeColors.primary
        },
        '&:hover': {
            backgroundColor: themeColors.primaryButtonBackgroundHover
        },
        '&:active': {
            backgroundColor: themeColors.primaryButtonBackgroundActive
        },
        '&[disabled]': {
            boxShadow: 'none',
            opacity: '0.5',
            cursor: 'not-allowed',
            pointerEvents: 'none'
        }
    },
    disclaimerSection: {
        width: '100%',
        padding: '50px 0px 50px',
        backgroundColor: colors.lightui_surface_4,
        textAlign: 'center',
        fontSize: '10px'
    },
    paddingTop: {
        paddingTop: '20px'
    },
    '@media screen and (max-width: 767px)': {
        breakPoint: {
            marginTop: '20px'
        },
        learnMore: {
            margin: 'auto'
        }
    }
};

Modal.setAppElement(`#___gatsby`);

class MctPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isMobile: false,
            title: '',
            updateCompatibleDeviceLink: false
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.setState({ updateCompatibleDeviceLink: isChinaUser() });
        this.loadTitleTranslation();
        this.resize();
        window.addEventListener('resize', this.resize.bind(this));
    }

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('main_container', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:mct_menstrual_cycle_tracking`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    resize() {
        if (typeof window !== `undefined`) {
            let renderMobile = window.innerWidth <= 767;
            if (renderMobile !== this.state.isMobile) {
                this.setState({ isMobile: renderMobile });
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    getMobileVideoLink() {
        if (isChinaUser()) {
            return this.getChinaVideoLink();
        } else {
            return this.getYoutubeLink();
        }
    }

    getYoutubeLink() {
        switch (i18next.language) {
            case 'zh-TW':
                return 'https://www.youtube.com/watch?v=mZjSf5lgu6M&feature=youtu.be';
            case 'in-ID':
                return 'https://youtu.be/bH-wyp5O4eo';
            case 'ko-KR':
                return 'https://www.youtube.com/watch?v=zQvo5ddKfVk&feature=youtu.be';
            case 'ja-JP':
                return 'https://www.youtube.com/watch?v=Ho1xk32TACI&feature=youtu.be';
            case 'th-TH':
                return 'https://www.youtube.com/watch?v=j197Or99D24&feature=youtu.be';
            case 'vi-VN':
                return 'https://youtu.be/h0ybLWoxfRo';
            default:
                return 'https://youtu.be/1aZbyw3HoH4';
        }
    }

    getEmbedVideoLink() {
        if (isChinaUser()) {
            return this.getChinaVideoLink();
        } else {
            return this.getEmbedYoutubeLink();
        }
    }

    getEmbedYoutubeLink() {
        switch (i18next.language) {
            case 'zh-TW':
                return 'https://www.youtube.com/embed/mZjSf5lgu6M?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'in-ID':
                return 'https://www.youtube.com/embed/bH-wyp5O4eo?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'ko-KR':
                return 'https://www.youtube.com/embed/zQvo5ddKfVk?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'ja-JP':
                return 'https://www.youtube.com/embed/Ho1xk32TACI?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'th-TH':
                return 'https://www.youtube.com/embed/j197Or99D24?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            case 'vi-VN':
                return 'https://www.youtube.com/embed/h0ybLWoxfRo?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
            default:
                return 'https://www.youtube.com/embed/1aZbyw3HoH4?rel=0&autohide=1&showinfo=0&modestbranding=1&theme=light';
        }
    }

    getChinaVideoLink() {
        return 'https://player.youku.com/embed/XNDM1MDA5MTUwOA';
    }

    getCompatibleSmartphoneLink = () => {
        return 'https://support.garmin.com/sas/connect/?contentId=pvL8aWsaLU2iKyvF8VrpP9';
    };

    getCompatibleDevicesLink = () => {
        return this.state.updateCompatibleDeviceLink
            ? `https://apps.garmin.cn/${i18next.language}/apps/bf2dfeea-5b3f-47ac-96e8-40cbd1be40b5?tab=compatible-devices`
            : `https://apps.garmin.com/${i18next.language}/apps/bf2dfeea-5b3f-47ac-96e8-40cbd1be40b5?tab=compatible-devices`;
    };

    render() {
        const { classes } = this.props;
        const button = this.state.isMobile ? (
            <Button size="large">
                <a href={this.getMobileVideoLink()}>{<Translate ns="prelogin_pages" content="watch_the_video" />}</a>
            </Button>
        ) : (
            <Button size="large" onClick={this.openModal}>
                {<Translate ns="prelogin_pages" content="watch_the_video" />}
            </Button>
        );
        const title = this.state.title;
        const pregnancyTrackingLink = `/features/pregnancy-tracking`;

        /*
         * The Button class was ot used below because of a bug in Gatsby where JSS randomly assigns classes to div rendered after nested components.
         */

        const blogLink =
            i18next.language === 'en-US' ? (
                <div className={classes.paddingTop}>
                    <button className={`${classes.blogButton} ${classes.learnMore}`}>
                        <a href="https://www.garmin.com/en-US/blog/?s=menstrual+cycle+tracking">
                            <Translate ns="prelogin_pages" content="mct_blog_button_text" />
                        </a>
                    </button>
                </div>
            ) : (
                ''
            );

        return (
            <Layout>
                <div className={classes.landingPage}>
                    <Helmet>
                        <title>Garmin Connect | {title}</title>
                        <meta name="title" content="Menstrual Cycle Tracking" />
                        <meta property="og:title" content="Menstrual Cycle Tracking" />
                        <meta
                            property="og:url"
                            content={`https://connect.garmin.com/features/menstrual-cycle-tracking`}
                        />
                        <meta
                            property="og:image"
                            content="http://static.garmincdn.com/com.garmin.connect/content/images/garmin-connect-fb3.gif"
                        />
                        <meta name="twitter:title" content="Garmin Menstrual Cycle Tracking" />
                    </Helmet>
                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="mct_menstrual_cycle_tracking" />}
                        contentList={[
                            <Translate
                                ns="prelogin_pages"
                                content="mct_menstrual_cycle_tracking_content"
                                params={{
                                    0: <a href={this.getCompatibleSmartphoneLink()} />,
                                    1: <a href={pregnancyTrackingLink} />
                                }}
                            />
                        ]}
                        desktopImage={HeroImage}
                        mobileImage={HeroImageMobile}
                        allowFullScreenImage={true}
                        allowFullScreenImageMobile={true}
                    >
                        <div>
                            <br />
                            {button}
                            <Modal
                                isOpen={this.state.modalIsOpen}
                                onRequestClose={this.closeModal}
                                contentLabel="Garmin Menstrual Cycle Tracking"
                                className={classes.modal}
                            >
                                <div className={classes.modalHeader}>
                                    <button className={classes.modalCloseButton} onClick={this.closeModal}>
                                        x
                                    </button>
                                </div>
                                <iframe
                                    title="Youtube id 1aZbyw3HoH4"
                                    src={this.getEmbedVideoLink()}
                                    frameBorder="0"
                                    allowFullScreen
                                    width="760"
                                    height="428"
                                />
                            </Modal>
                        </div>
                    </InfoSection>
                    <div className={classes.breakPoint}>
                        <InfoSection
                            contentTitle={<Translate ns="prelogin_pages" content="mct_for_everyone" />}
                            contentList={[<Translate ns="prelogin_pages" content="mct_for_everyone_content" />]}
                            desktopImage={walkingImage}
                            mobileImage={walkingImageMobile}
                            allowFullScreenImage={true}
                            textAlign="right"
                            textColor="#ffffff"
                        />
                    </div>

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="mct_log_daily_symptoms" />}
                        contentList={[<Translate ns="prelogin_pages" content="mct_log_daily_symptoms_content" />]}
                        desktopImage={phoneImage}
                        mobileImage={phoneImageMobile}
                        minimizeImage={true}
                    />

                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="mct_period_fertility_prediction" />}
                        contentList={[
                            <Translate ns="prelogin_pages" content="mct_period_fertility_prediction_content" />
                        ]}
                        desktopImage={yogaImage}
                        mobileImage={yogaImageMobile}
                        allowFullScreenImage={true}
                        textColor="#ffffff"
                    />
                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="mct_health_and_nutrition" />}
                        contentList={[
                            <div>
                                <Translate ns="prelogin_pages" content="mct_health_and_nutrition_content" />
                            </div>,
                            blogLink
                        ]}
                        desktopImage={teaImage}
                        mobileImage={teaImageMobile}
                        allowFullScreenImage={true}
                        textAlign="right"
                        textColor="#ffffff"
                    />
                    <InfoSection
                        contentTitle={<Translate ns="prelogin_pages" content="mct_for_your_wrist" />}
                        contentList={[
                            <Translate
                                ns="prelogin_pages"
                                tag="div"
                                content="mct_for_your_wrist_content"
                                params={{
                                    0: this.getCompatibleDevicesLink()
                                }}
                            />,
                            <div className={classes.paddingTop}>
                                <Button size="large">
                                    <a href={this.getCompatibleDevicesLink()}>
                                        {<Translate ns="prelogin_pages" content="mct_compatible_devices_label" />}
                                    </a>
                                </Button>
                            </div>
                        ]}
                        desktopImage={vivoactiveImage}
                        mobileImage={vivoactiveImage}
                        minimizeImage={true}
                    />

                    <div className={classes.disclaimerSection}>
                        <Translate
                            ns="prelogin_pages"
                            tag="div"
                            content="disclaimer_text"
                            params={{
                                0: this.getCompatibleSmartphoneLink()
                            }}
                        />
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(MctPage);
